import React, { Component, StrictMode } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'));
const InviteUser = React.lazy(() => import('./views/pages/inviteuser/InviteUser'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ExamView = React.lazy(() => import('./views/pages/exam/ExamView'));

class App extends Component {

  render() {
    return (
      <StrictMode>
        <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login" render={props => <Login {...props}/>} />
                <Route exact path="/forgot-password" name="ForgotPassword" render={props => <ForgotPassword {...props}/>} />
                <Route exact path="/forgot-password/:token_id" name="ForgotPassword" render={props => <ForgotPassword {...props}/>} />
                <Route exact path="/exam/:exam_id" name="ExamView" render={props => <ExamView {...props}/>} />
                <Route exact path="/invite-user" name="InviteUser" render={props => <InviteUser {...props}/>} />
                <Route exact path="/invite-user/:invite_id" name="InviteUser" render={props => <InviteUser {...props}/>} />
                <Route exact path="/register" name="" render={props => <Register {...props}/>} />
                <Route exact path="/404" name="404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </StrictMode>
    );
  }
}

export default App;
